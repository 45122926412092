var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    },
    scopedSlots: _vm._u([{
      key: "overlay",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        }), _c('b-spinner', {
          attrs: {
            "type": "grow",
            "variant": "dark"
          }
        }), _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow",
            "variant": "secondary"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "row mt-4 create-new"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "bg-success p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(" Bulk Upload Single ID AX ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "lg": "6"
    }
  }, [_c('b-row', [_c('b-form-group', {
    staticClass: "col"
  }, [_c('b-form-file', {
    attrs: {
      "accept": ".xlsx",
      "placeholder": "choose a .xlsx file...",
      "drop-placeholder": "Drop file here..."
    },
    model: {
      value: _vm.file_item,
      callback: function ($$v) {
        _vm.file_item = $$v;
      },
      expression: "file_item"
    }
  }), _c('b-button', {
    staticClass: "badge badge-danger rounded",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" reset ")])], 1), _c('b-form-group', {
    staticClass: "col-md-3"
  }, [_c('b-link', {
    staticClass: "btn btn-outline",
    staticStyle: {
      "color": "#28a745",
      "border-color": "#28a745"
    },
    attrs: {
      "href": _vm.urlTemplate.TEMPLATE_BULK_UPLOAD_SINGLE
    }
  }, [_vm._v(" Template "), _c('i', {
    staticClass: "fa fa-file-excel-o"
  })])], 1)], 1), _c('b-row')], 1)], 1)], 1)])])]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "bg-success p-3"
  }, [_c('h5', {
    staticClass: "card-title mb-0 font-weight-bold"
  }, [_vm._v(" Preview "), _vm.bulkData.length ? _c('span', [_vm._v("( Page " + _vm._s(_vm.currentPage) + " | Total : " + _vm._s(_vm.bulkData.length) + " )")]) : _vm._e()])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-form', [_c('b-row', [_c('b-col', [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.uploadData
    }
  }, [_c('i', {
    staticClass: "fa fa-upload"
  }), _vm._v(" Upload ")])], 1), _vm.bulkData.length ? _c('b-row', {
    staticClass: "justify-content-start mr-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.duplicateDataRemoved.length,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1) : _vm._e()], 1), _c('b-row', [_c('b-col', {
    staticClass: "p-3",
    staticStyle: {
      "overflow": "auto"
    }
  }, [_c('div', {
    staticClass: "table-wrap"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    }
  }, [_c('b-table', {
    staticStyle: {
      "white-space": "unset",
      "min-height": "500px"
    },
    attrs: {
      "show-empty": "",
      "striped": "",
      "hover": "",
      "sticky-header": "600px",
      "items": _vm.bulkData,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(action)",
      fn: function (data) {
        return [_c('i', {
          staticClass: "fa fa-trash fa-2 text text-danger",
          staticStyle: {
            "cursor": "pointer",
            "font-size": "24px"
          },
          on: {
            "click": function ($event) {
              return _vm.handleDelete(data);
            }
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function (data) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (data.index + 1)) + ". ")];
      }
    }, {
      key: "cell(vendor_id_ax)",
      fn: function (data) {
        return [_c('b-form-input', {
          staticStyle: {
            "width": "200px"
          },
          attrs: {
            "type": "text",
            "step": "any"
          },
          model: {
            value: _vm.bulkData[data.index].vendor_id_ax,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[data.index], "vendor_id_ax", $$v);
            },
            expression: "bulkData[data.index].vendor_id_ax"
          }
        })];
      }
    }, {
      key: "cell(item_id)",
      fn: function (data) {
        return [_c('b-form-input', {
          class: _vm.getMarkedFormItemID(_vm.bulkData[data.index].item_id),
          staticStyle: {
            "width": "200px"
          },
          attrs: {
            "type": "number",
            "step": "any"
          },
          model: {
            value: _vm.bulkData[data.index].item_id,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[data.index], "item_id", $$v);
            },
            expression: "bulkData[data.index].item_id"
          }
        })];
      }
    }, {
      key: "cell(single_idax)",
      fn: function (data) {
        return [_c('b-form-input', {
          class: _vm.getMarkedFormSingleIdAx(_vm.bulkData[data.index].single_idax),
          staticStyle: {
            "width": "200px"
          },
          attrs: {
            "type": "number",
            "step": "any"
          },
          model: {
            value: _vm.bulkData[data.index].single_idax,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[data.index], "single_idax", $$v);
            },
            expression: "bulkData[data.index].single_idax"
          }
        })];
      }
    }, {
      key: "cell(name)",
      fn: function (data) {
        return [_c('b-form-input', {
          staticStyle: {
            "width": "200px"
          },
          attrs: {
            "type": "text",
            "step": "any"
          },
          model: {
            value: _vm.bulkData[data.index].name,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[data.index], "name", $$v);
            },
            expression: "bulkData[data.index].name"
          }
        })];
      }
    }, {
      key: "cell(base_price)",
      fn: function (data) {
        return [_c('b-form-input', {
          staticStyle: {
            "width": "200px"
          },
          attrs: {
            "type": "number",
            "step": "any"
          },
          model: {
            value: _vm.bulkData[data.index].base_price,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[data.index], "base_price", $$v);
            },
            expression: "bulkData[data.index].base_price"
          }
        })];
      }
    }, {
      key: "cell(publisher)",
      fn: function (data) {
        return [_c('b-form-input', {
          staticStyle: {
            "width": "200px"
          },
          attrs: {
            "type": "text",
            "step": "any"
          },
          model: {
            value: _vm.bulkData[data.index].publisher,
            callback: function ($$v) {
              _vm.$set(_vm.bulkData[data.index], "publisher", $$v);
            },
            expression: "bulkData[data.index].publisher"
          }
        })];
      }
    }])
  })], 1)], 1)])], 1)], 1)], 1)])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }