<template>
  <b-overlay rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="row mt-4 create-new">
      <div class="col-md-12">
        <div class="card">
          <div class="bg-success p-3">
            <h5 class="card-title mb-0 font-weight-bold">
              Bulk Upload Single ID AX
            </h5>
          </div>
          <div class="card-body">
            <b-row align-v="center">
              <b-col lg="6">
                <b-row>
                  <b-form-group class="col">
                    <b-form-file
                      v-model="file_item"
                      accept=".xlsx"
                      placeholder="choose a .xlsx file..."
                      drop-placeholder="Drop file here..."
                    >
                    </b-form-file>
                    <b-button
                      @click="reset"
                      variant="link"
                      class="badge badge-danger rounded"
                    >
                      reset
                    </b-button>
                  </b-form-group>
                  <b-form-group class="col-md-3">
                    <b-link
                      class="btn btn-outline"
                      style="color: #28a745; border-color: #28a745"
                      :href="urlTemplate.TEMPLATE_BULK_UPLOAD_SINGLE"
                    >
                      Template <i class="fa fa-file-excel-o"></i>
                    </b-link>
                  </b-form-group>
                </b-row>
                <b-row> </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
    <b-row>
      <b-col cols="12">
        <div class="card">
          <div class="bg-success p-3">
            <h5 class="card-title mb-0 font-weight-bold">
              Preview
              <span v-if="bulkData.length"
                >( Page {{ currentPage }} | Total :
                {{ bulkData.length }} )</span
              >
            </h5>
          </div>
          <div class="card-body">
            <b-form>
              <b-row>
                <b-col>
                  <b-button variant="primary" @click="uploadData">
                    <i class="fa fa-upload"></i>
                    Upload
                  </b-button>
                </b-col>
                <b-row
                  class="justify-content-start mr-1"
                  v-if="bulkData.length"
                >
                  <b-col lg="3">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="duplicateDataRemoved.length"
                      :per-page="perPage"
                      aria-controls="my-table"
                    />
                  </b-col>
                </b-row>
              </b-row>
              <b-row>
                <b-col class="p-3" style="overflow: auto;">
                  <div class="table-wrap">
                    <b-overlay
                      :show="isLoading"
                      rounded="lg"
                      opacity="0.6"
                      spinner-variant="primary"
                    >
                      <b-table
                        show-empty
                        striped
                        hover
                        sticky-header="600px"
                        :items="bulkData"
                        :fields="fields"
                        style="white-space: unset; min-height: 500px;"
                      >
                        <template #cell(action)="data">
                          <i
                            class="fa fa-trash fa-2 text text-danger"
                            style="cursor: pointer; font-size: 24px;"
                            @click="handleDelete(data)"
                          ></i>
                        </template>
                        <template #cell(no)="data">
                          {{ (currentPage - 1) * perPage + (data.index + 1) }}.
                        </template>
                        <template #cell(vendor_id_ax)="data">
                          <b-form-input
                            type="text"
                            step="any"
                            v-model="bulkData[data.index].vendor_id_ax"
                            style="width:200px"
                          >
                          </b-form-input>
                        </template>
                        <template #cell(item_id)="data">
                          <b-form-input
                            type="number"
                            step="any"
                            v-model="bulkData[data.index].item_id"
                            style="width:200px"
                            :class="
                              getMarkedFormItemID(bulkData[data.index].item_id)
                            "
                          >
                          </b-form-input>
                        </template>
                        <template #cell(single_idax)="data">
                          <b-form-input
                            type="number"
                            step="any"
                            v-model="bulkData[data.index].single_idax"
                            style="width:200px"
                            :class="
                              getMarkedFormSingleIdAx(
                                bulkData[data.index].single_idax,
                              )
                            "
                          >
                          </b-form-input>
                        </template>
                        <template #cell(name)="data">
                          <b-form-input
                            type="text"
                            step="any"
                            v-model="bulkData[data.index].name"
                            style="width:200px"
                          >
                          </b-form-input>
                        </template>
                        <template #cell(base_price)="data">
                          <b-form-input
                            type="number"
                            step="any"
                            v-model="bulkData[data.index].base_price"
                            style="width:200px"
                          >
                          </b-form-input>
                        </template>
                        <template #cell(publisher)="data">
                          <b-form-input
                            type="text"
                            step="any"
                            v-model="bulkData[data.index].publisher"
                            style="width:200px"
                          >
                          </b-form-input>
                        </template>
                      </b-table>
                    </b-overlay>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import XLSX from 'xlsx';
import constant from '../../store/constant';

export default {
  name: 'UploadItemIDAX',
  data() {
    return {
      urlTemplate: constant.URL_STATIC,
      currentPage: 1,
      perPage: 400,
      file_item: null,
      fileDataRaw: [],
      bulkData: [],
      duplicateDataRemoved: [],
      fields: [
        {
          key: 'action',
          label: '',
        },
        {
          key: 'no',
          label: 'No.',
        },
        {
          key: 'vendor_id_ax',
          label: 'Vendor ID AX',
        },
        {
          key: 'item_id',
          label: 'Item ID',
        },
        {
          key: 'single_idax',
          label: 'Single ID AX',
        },
        {
          key: 'name',
          label: 'Item Name',
        },
        {
          key: 'base_price',
          label: 'Price',
        },
        {
          key: 'publisher',
          label: 'Publisher',
        },
      ],
    };
  },

  watch: {
    currentPage: function() {
      this.dataToTable();
    },

    async file_item() {
      this.currentPage = 1;
      if (!this.file_item) {
        this.reset();
        this.$store.commit(`ItemSoax/setLoading`, false);
        return;
      }
      this.convertToObject();
    },

    successMessage: function() {
      if (!this.successMessage) return;
      this.msgAlert('success', this.successMessage, 5000);
      this.bulkData = [];
      const totalPage = this.duplicateDataRemoved?.length / this.perPage;
      if (this.currentPage <= totalPage) {
        this.currentPage = parseInt(this.currentPage) + 1;
        return;
      }
      this.reset();
      // this.$router.push('/ax/items');
    },
    errorMessage: function() {
      if (!this.errorMessage) return;
      this.msgAlert('error', this.errorMessage, 5000);
    },

    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Bulk Upload Single ID AX | Content Management System Ebooks Gramedia.com';
      },
    },
  },

  computed: {
    ...mapState({
      isError: (state) => state.ItemSoax.isError,
      isLoading: (state) => state.ItemSoax.isLoading,
      errorMessage: (state) => state.ItemSoax.errorMessage,
      successMessage: (state) => state.ItemSoax.successMessage,
    }),
  },

  methods: {
    ...mapActions('ItemSoax', ['bulkUploadSingleIdAx']),

    reset() {
      this.file_item = null;
      this.bulkData = [];
      this.fileDataRaw = [];
      this.$store.commit(`ItemSoax/setLoading`, false);
    },

    convertToObject() {
      this.$store.commit(`ItemSoax/setLoading`, true);
      const reader = new FileReader();
      reader.readAsBinaryString(this.file_item);
      reader.onload = () => {
        const workbook = XLSX.read(reader.result, {
          type: 'binary',
          cellDates: true,
          raw: true,
          dateNF: 'yyyy-mm-dd hh:mm:ss',
        });
        const fileData = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
        );
        this.fileDataRaw = fileData;
        this.dataToTable();
        this.$store.commit(`ItemSoax/setLoading`, false);
      };
    },

    dataToTable() {
      this.duplicateDataRemoved = [
        ...new Map(
          this.fileDataRaw.map((v) => [
            v['External item number'],
            {
              vendor_id_ax: v['Vendor account'],
              item_id: v['External item number']
                ? v['External item number'].toString().replace(/\D/g, '')
                : '',
              single_idax: isNaN(v['Product number'])
                ? ''
                : v['Product number'],
              name: v['Product name'],
              base_price: v['Sales price (IDR)'] ?? 0,
              publisher: v['Vendor name'],
              vendor: v['Vendor name'],
            },
          ]),
        ).values(),
      ];

      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      this.bulkData = this.duplicateDataRemoved.slice(startIndex, endIndex);
    },

    uploadData() {
      /** if no data selected */
      if (!this.bulkData.length) {
        this.msgAlert('error', 'No files to upload !', 5000);
        return;
      }

      /** a maximum of only 1000 lines */
      if (this.bulkData.length > 1000) {
        this.msgAlert('error', 'a maximum data of only 1000 lines', 5000);
        return;
      }

      this.bulkUploadSingleIdAx({ items: this.bulkData });
    },

    getMarkedFormItemID(item_id) {
      if (!item_id) {
        return {
          'bg-danger': true,
        };
      }
      return {
        'bg-danger': false,
      };
    },

    getMarkedFormSingleIdAx(single_idax) {
      if (!single_idax) {
        return {
          'bg-danger': true,
        };
      }
      return {
        'bg-danger': false,
      };
    },

    handleDelete(data) {
      this.$swal({
        title: 'Are you sure ?',
        text: `You will delete data`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
      }).then((result) => {
        if (result.isConfirmed) {
          this.bulkData.splice(data.index, 1);
        }
      });
    },

    msgAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: 'true',
        position: 'top-end',
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
};
</script>
